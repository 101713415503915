const customerData = [
  {
    title:
      "After graduating bootcamp, I was looking for a mentor to keep me sharp. ",
    content:
      "Gaurav has helped me practicing algorithms and taught me some cool tricks with JavaScript and React that I never learned before, this has helped me a lot towards getting a job in a short amount of time.",
    customerName: "Daniel Michael",
    customerTitle: "Frontend React Engineer",
    location: "NY, USA",
    customerImage: "/student/daniel_m.jpeg",
  },
  {
    title: "University Student - React, Node, Express & JavaScript projects ",
    content:
      "Gaurav helped me out with my web application class projects. He really knows his stuff and was able to provide valuable feedback on how I can improve my work. He explained everything to me step by step instead of giving me the answers unlike some other tutors I had before; He really helped me understand the material I've been studying. The final project we worked on was about creating a web and mobile application to send and receive emails working with material UI, react, NodeJs, REST API, Docker, authentication, etc. He's also very patient. I'm slow in executing code and Gaurav was very understanding of this. I will definitely be seeking tutoring from Gaurav again.",
    customerName: "Tina Yeganeh",
    customerTitle: "Student",
    location: "CA, USA",
    customerImage: "/student/tina.jpg",
  },
  {
    title: "Bootcamp student",
    content:
      "Gaurav is an excellent tutor who goes above and beyond to help you understand concepts and solve your coding problems. He is dedicated to helping you learn and I would recommend him to anyone struggling with JavaScript.",
    customerName: "Zemen Teshome",
    customerTitle: "Student",
    location: "Canada",
    customerImage: "/student/zemen.jpeg",
  },
];

export default customerData;
