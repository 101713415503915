import React from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import CustomerCard from "../components/CustomerCard";
import LabelText from "../components/LabelText";
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";
import customerData from "../data/customer-data";
import EmailImage from "../../content/assets/email-image.svg";
import { FaReact, FaHtml5, FaGitAlt, FaNode } from "react-icons/fa";
import {
  SiCss3,
  SiJavascript,
  SiMongodb,
  SiGraphql,
  SiRedux,
  SiTypescript,
  SiPostgresql,
  SiFirebase,
} from "react-icons/si";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Video from "../components/video";
export default ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Gaurav Sen" />
    {/* <section className="py-10 md:py-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="">
          <h1 className="animate-pulse-once text-transparent bg-clip-text bg-gradient-to-r from-primary to-fushia-600 text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Welcome!
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-medium text-gray-600">
            Learn web development with ease.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-medium text-gray-600 ">
            Gain clarity to code.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-medium text-gray-600">
            Master the fundamentals by building real world projects.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-medium text-gray-600">
            Tweak up your development skills!
          </p>
          {/* <p className="mt-8 md:mt-12">
            <Button size="lg">Get Started</Button>
          </p>
          <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> }
        </div>
        <div className="sm:text-lg text-xs lg:pt-20 lg:w-1/2 flex flex-wrap space-x-4 space-y-4 sm:space-x-6 sm:space-y-4 justify-center items-center content-evenly">
          <figure className="text-center ">
            <SiJavascript
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl bg-black stroke-current text-yellow-300"
              title="JavaScript"
            />
            <figcaption>Javascript</figcaption>
          </figure>
          <figure className="text-center">
            <FaReact
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-blue-400"
              title="React icon"
            />
            <figcaption>React</figcaption>
          </figure>
          <figure className="text-center">
            <FaHtml5
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl bg-white fill-current text-orange-600"
              title="HTML"
            />
            <figcaption>HTML</figcaption>
          </figure>
          <figure className="text-center">
            <SiCss3
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl bg-white stroke-current text-blue-700"
              title="CSS"
            />
            <figcaption>CSS</figcaption>
          </figure>
          <figure className="text-center">
            <SiTypescript
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl fill-current text-blue-500 bg-white"
              title="Typescript"
            />
            <figcaption>Typescript</figcaption>
          </figure>
          <figure className="text-center">
            <FaNode
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl"
              title="Node.js"
            />
            <figcaption>NodeJs</figcaption>
          </figure>
          {/* <figure className="text-center">
            <SiRedux
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-purple-600"
              title="Redux"
            />
            <figcaption>Redux</figcaption>
          </figure> }
          <figure className="text-center">
            <SiMongodb
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-green-700"
              title="MongoDB"
            />
            <figcaption>MongoDB</figcaption>
          </figure>
          <figure className="text-center">
            <SiPostgresql
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-blue-300"
              title="PostgresSQL"
            />
            <figcaption>Postgres SQL</figcaption>
          </figure>
          <figure className="text-center">
            <FaGitAlt
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-orange-600"
              title="Git"
            />
            <figcaption>Git</figcaption>
          </figure>
          {/* <figure className="text-center">
            <SiGraphql
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-pink-500"
              title="GraphQL"
            />
            <figcaption>GraphQL</figcaption>
          </figure> }
          <figure className="text-center">
            <SiFirebase
              className="w-14 h-14 sm:w-24 sm:h-24 rounded-xl stroke-current text-yellow-500"
              title="Firebase"
            />
            <figcaption>Firebase</figcaption>
          </figure>
        </div>
      </div >
</section >  */}

    <div className="container mx-auto px-8">
      <h1 className="text-4xl lg:text-5xl xl:text-6xl font-semibold leading-none">
        Learn web development with ease!
      </h1>
    </div>

    <SplitSection
      id="aboutme"
      secondarySlot={
        <div className="lg:pl-20 xl:pl-20 space-y-6">
          <h3 className="text-3xl font-semibold leading-tight">
            Hi, I'm Gaurav
          </h3>
          <p className="mt-8 text-lg leading-relaxed">
            A Software Engineer by profession with{" "}
            <strong>{new Date().getFullYear() - 2013} years</strong> of industry
            experience.
          </p>
          <p className=" mt-0 text-lg leading-relaxed">
            In 2020 I started tutoring as a hobby. A way to earn a passive side
            income.
          </p>
          <p className=" mt-0 text-lg leading-relaxed">
            After receiving positive feedback from a large number of students, I
            took it up more actively. It gives me immense joy to see my students
            succeed and grow with the skills that I've taught them.
          </p>
          <p className=" mt-0 text-lg leading-relaxed">
            This website is an attempt to share my knowledge and experience.
          </p>
          <p className=" mt-0 text-lg leading-relaxed">
            I'm willing to share my knowledge and experience with you. Are you
            willing to learn?
          </p>
        </div>
      }
      primarySlot={
        <div className="sm:h-3/4 sm:w-3/4 mx-auto my-auto">
          <GatsbyImage
            image={data.fileName.childImageSharp.gatsbyImageData}
            className="rounded-3xl"
            imgClassName="rounded-3xl"
            alt="Gaurav's Image"
          />
        </div>
      }
    />

    <section id="youtube" className="py-10">
      <div className="container mx-auto text-center space-y-2">
        <h2 className="text-3xl font-semibold leading-tight">
          Latest YouTube Videos
        </h2>
        <div className="flex space-y-2 flex-col px-2 md:flex-row md:space-x-2 md:space-y-0 ">
          <div className="flex-1">
            <Video
              videoSrcURL="https://www.youtube.com/embed/44Aal1FkgeA"
              videoTitle="Faster page loading with async and defer!"
            />
          </div>
          <div className="flex-1">
            <Video
              videoSrcURL="https://www.youtube.com/embed/UMlzj_ZdEV8"
              videoTitle="Emmet for faster CSS Workflows!"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="mentoring" className="py-10 ">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold leading-tight">Mentoring</h2>
        <p className="mt-8 text-lg font-light leading-relaxed">
          I offer 1 - 1 mentoring online.
        </p>
        <div className="w-full flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-2xl">Web Development</p>
              <p className="mt-4"></p>
              <p></p>
              <ul className="text-left">
                <li>Learn most wanted web development skills</li>
                <li>Earn an income as web developer</li>
                <li>Build beautiful mobile friendly websites</li>
                <li>Create a portfolio</li>
                <li>Hosting your websites live on the internet</li>
                <li>Learn industry best practices</li>
                <li>Using tools such as VS Code, Git, NPM, Webpack</li>
              </ul>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-2xl">Frontend</p>
              <p className="mt-4 text-left">
                What the user sees on the browser is known as frontend. HTML,
                CSS and Javascript are the foundations for web development.
                Learn these correctly and you can master any library or
                framework with ease.
              </p>
              <ul className="font-semibold text-xl">
                <li>HTML, CSS, JavaScript</li>
                <li>Modern JavaScript ES6+</li>
                <li>React</li>
                <li>Redux</li>
                <li>Typescript</li>
              </ul>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-2xl">Backend</p>
              <p className="text-left mt-4">
                Backend deals with processing the information that is required
                by the frontend. The dynamic content for a website is served
                from the backend. The frontend fetches data from the backend
                using APIs.
              </p>

              <ul className="font-semibold text-xl">
                <li>Node.js</li>
                <li>Express</li>
                <li>Mongo DB</li>
                <li>Postgres DB</li>
                <li>GraphQL</li>
                <li>Firebase</li>
              </ul>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section id="testimonials" className="py-5 lg:py-10">
      <div className="container mx-auto ">
        <LabelText className="mb-8 text-center text-3xl font-semibold leading-tight">
          What Clients are saying
        </LabelText>
        <div className="w-full lg:-pl-2 flex-col">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section
      id="contact-me"
      className="container mx-auto my-10 bg-gray-200 rounded-lg text-center"
    >
      <form
        name="contactMe"
        className="p-10 "
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col sm:w-1/2">
            <h3 className="text-3xl font-semibold">Interested in learning?</h3>
            <p className="text-xl">Send me a message</p>
            <figure className="self-center rounded-lg p-2 object-center w-2/3 h-2/3">
              <EmailImage className="object-contain" />
            </figure>
          </div>
          <div className="sm:w-1/2 flex flex-col space-y-8">
            <input type="hidden" name="form-name" value="contactMe" />
            <p style={{ display: "none" }}>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <input
              required
              className="h-12 rounded-sm p-2 focus:outline-none focus:ring focus:ring-primary"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
            />
            <input
              required
              className="h-12 rounded-sm p-2 focus:outline-none focus:ring focus:ring-primary"
              type="email"
              name="email"
              id="email"
              placeholder="Email"
            />
            <textarea
              required
              placeholder="Message"
              className="rounded-sm p-2 focus:outline-none focus:ring focus:ring-primary"
              name="content"
              id="content"
              cols="30"
              rows="10"
            ></textarea>
            <Button
              type="submit"
              className="ring-1 ring-offset-primary text-primary"
              size="xl"
            >
              Send Message
            </Button>
          </div>
        </div>
      </form>

      <p className="mt-8"></p>
    </section>
  </Layout>
);

export const query = graphql`
  {
    fileName: file(relativePath: { eq: "profile/Gaurav-full.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
  }
`;
