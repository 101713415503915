import React from "react";
import Card from "./Card";
import { VscLocation } from "react-icons/vsc";
import * as cardStyles from "./customer-card.module.css";

const CustomerCard = ({ customer }) => (
  <Card className={`${cardStyles.customer__card} mb-8`}>
    <p className="text-xl font-semibold">{customer.title}</p>
    <blockquote>{customer.content}</blockquote>
    <div className="flex mt-8 justify-center items-center">
      <div className="flex-initial w-16 h-auto mr-4 bg-gradient-to-tr from-primary-lighter to-fushia-600 rounded-full p-1">
        <img
          className="object-cover object-center rounded-full ring-2 ring-white"
          src={customer.customerImage}
          alt={customer.customerName}
        />
      </div>
      <div className="flex-1 my-auto">
        <p className="mb-0">{customer.customerName}</p>
        <p className="text-sm text-gray-600 mb-1">{customer.customerTitle}</p>
        <p className="text-sm  text-gray-600  mb-1">
          <VscLocation
            size="1.2rem"
            className="-ml-1 inline fill-current text-primary-lighter"
          />
          {customer.location}
        </p>
      </div>
    </div>
  </Card>
);

export default CustomerCard;
